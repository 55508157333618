




import Vue from "vue";
export default Vue.extend({
  fetch({ app, redirect }) {
    if (app.$auth.$state.loggedIn) {
      redirect("/project-search");
    }
  },
  mounted() {
    if (!this.$auth.$state.loggedIn) {
      this.$router.push("/intro");
    } else {
      this.$router.push("/project-search");
    }
  }
});
